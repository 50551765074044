/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-11-26",
    versionChannel: "nightly",
    buildDate: "2023-11-26T00:15:21.702Z",
    commitHash: "d3b9d8a4516a985a78d8a081a764aacabd8e3b6d",
};
